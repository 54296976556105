import axios from "axios";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { PopupState } from "../atoms/Users";
import { ArrowBack } from "@material-ui/icons";
import { Fab } from "@material-ui/core";
import QRgenerator from "../pages/QRgenerator";
import { hashed } from "../services/hash";
import "../styles/Form.css";
import { useEffect } from "react";
import dateDiff from "./date";

const Form = () => {
  const [Nom, setNom] = useState("");
  const [Prenom, setPrenom] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [Tel, setTel] = useState("");
  const [Adress, setAdress] = useState("");
  const [Ville, setVille] = useState("");
  const [Activite, setActivite] = useState("");
  const [payment, setPayment] = useState("");
  const [lastpayment] = useState("01-11-2022");
  const [file, setFile] = useState();
  const [qr, setQr] = useState("");

  useEffect(() => {
    setQr(hashed(date + Adress) + Nom);
  }, [Adress, date, Nom]);

  const history = useHistory();
  const [Pop, setPop] = useRecoilState(PopupState);

  const handlePicture = (e) => {
    console.log(Pop);
    setFile(e.target.files[0]);
  };
  const handleRegIster = async (e) => {
    e.preventDefault();
    console.log(dateDiff(payment, lastpayment));
  };
  const handleRegIst = async (e) => {
    e.preventDefault();
    const d = new Date();
    var linkImage = "";

    const datas = new FormData();
    datas.append("name", Nom);
    datas.append("file", file, file.name);
    await axios
      .post("http://localhost:5000/upload", datas)
      .then((data) => (linkImage = data.data.linkImage))
      .catch((err) => alert("Image non enregitre"));
    await axios({
      method: "post",
      url: "https://eorfhj2o3yyxx0y.m.pipedream.net",
      data: {
        datetime:
          d.getDate() +
          d.getMonth() +
          d.getFullYear() +
          d.getHours() +
          d.getMinutes(),
        email,
        firstname: Nom,
        lastname: Prenom,
        photo: linkImage,
        birthdate: date,
        phone: Tel,
        address: Adress,
        city: Ville,
        activity: Activite,
        payment,
        lastpayment,
        ID: qr,
      },
    })
      .then((res) => {
        setPop({
          text: `${(Prenom, Nom)} est inscrit(e) ☻`,
          show: true,
        });
        setTimeout(() => {
          setPop(null);
        }, 5000);
        history.push("/");
      })
      .catch((err) => {
        alert("Veuillez réessayer!");
        console.log(err);
      });
  };

  return (
    <div>
      <div className="container ">
        <div className="container ">
          <div className="card center" style={{ padding: "50px" }}>
            <Link to="/" className="left">
              <Fab style={{ marginRight: 10, marginLeft: 10 }} color="primary">
                <ArrowBack />
              </Fab>
            </Link>
            <h2>Inscription</h2>
            <form action="" onSubmit={handleRegIster}>
              <div className="row">
                <div className="col s12 l6">
                  <div className="input-field">
                    <input
                      required
                      type="text"
                      name="Prénoms"
                      onChange={(e) => setPrenom(e.target.value)}
                      value={Prenom}
                      id="email"
                    />
                    <label htmlFor="Prénoms">Prénoms</label>
                  </div>
                </div>
                <div className="col s12 l6">
                  <div className="input-field">
                    <input
                      required
                      type="text"
                      name="Nom de famille
"
                      onChange={(e) => setNom(e.target.value)}
                      value={Nom}
                      id="email"
                    />
                    <label htmlFor="email">Nom de famille</label>
                  </div>
                </div>

                <div className="col s12 l6">
                  <div className="input-field">
                    <input
                      required
                      type="date"
                      name="date"
                      id="date"
                      onChange={(e) => setDate(e.target.value)}
                      value={date}
                      className="datepicker"
                    />
                    <label htmlFor="date">Date de naissance</label>
                  </div>
                </div>
                <div className="col s12 l6">
                  <div className="input-field">
                    <input
                      required
                      type="text"
                      name="Adresse email"
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                    <label htmlFor="email">Adresse email</label>
                  </div>
                </div>
                <div className="col s12 l6">
                  <div className="input-field">
                    <input
                      required
                      type="text"
                      name="Numéro de téléphone"
                      id="email"
                      onChange={(e) => setTel(e.target.value)}
                      value={Tel}
                    />
                    <label htmlFor="email">Numéro de téléphone</label>
                  </div>
                </div>
                <div className="col s12 l6">
                  <div className="input-field">
                    <input
                      required
                      type="text"
                      name="Adresse domicile"
                      id="email"
                      onChange={(e) => setAdress(e.target.value)}
                      value={Adress}
                    />
                    <label htmlFor="email">Adresse domicile</label>
                  </div>
                </div>
                <div className="col s12 l6">
                  <div className="input-field">
                    <input
                      required
                      type="text"
                      name="Ville"
                      onChange={(e) => setVille(e.target.value)}
                      value={Ville}
                      id="email"
                    />
                    <label htmlFor="email">Ville</label>
                  </div>
                </div>
                <div className="col s12 l6">
                  <div className="input-field">
                    <input
                      required
                      type="text"
                      name="Ville"
                      onChange={(e) => setActivite(e.target.value)}
                      value={Activite}
                      id="activite"
                    />
                    <label htmlFor="email">Activité</label>
                  </div>
                </div>
                <div className="col s12 l6">
                  <div className="input-field">
                    <input
                      required
                      type="date"
                      name="payment"
                      onChange={(e) => setPayment(e.target.value)}
                      value={payment}
                      id="email"
                    />
                    <label htmlFor="email">Payement</label>
                  </div>
                </div>
                <div className="col s12 l6">
                  <div className="input-field">
                    <p>Photo d'identité</p>
                    <input
                      className="inputFile"
                      type="file"
                      name="file"
                      onChange={(e) => handlePicture(e)}
                      accept=".png, .jpg, .jpeg"
                    />
                  </div>
                </div>
                <div className="col s12 l6">
                  <div className="input-field">
                    <p style={{ position: "absolute", top: "-2em" }}>
                      Date du prochain payement
                    </p>
                    <input
                      type="text"
                      name="lastpayment"
                      id="email"
                      value={lastpayment}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col s12 l6">
                  <div className="input-field">
                    <p style={{ position: "absolute", top: "-2em" }}>
                      Identifiant
                    </p>
                    <input
                      type="text"
                      name="qrcode"
                      id="email"
                      value={qr}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <QRgenerator ID={qr} nom={Nom} />
              <button type="submit" className="btn indigo">
                Valider
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
