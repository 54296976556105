export default function dateDiff(d1, d2) {
  let date1 = new Date(d1);
  let date2 = new Date(d2);

  let timediff = date2.getTime() - date1.getTime();

  let daydiff = timediff / (1000 * 3600 * 24);

  return daydiff;
}
