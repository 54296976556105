import React from "react";

const PageNotFound = () => {
  return (
    <div className="container">
      <h1>
        {" "}
        <i className="red-text">"Page 404"</i> <br /> Page introuvable!!!
      </h1>
    </div>
  );
};

export default PageNotFound;
